<template>
  <v-alert
    :outlined="outlined"
    class="my-4 mx-4"
    icon="fa fa-exclamation-triangle"
    :color="color"
    prominent
    border="left"
  >
    <div class="pl-4">
      <div class="title">
        {{title| t}}
      </div>

      <div>
        {{description| t}}
      </div>

      <v-spacer></v-spacer>

      <v-btn
        @click="$router.go(-1)"
        small
        outlined
        class="mt-4 mr-4"
        :color="buttonColor"
      >
        <v-icon left>fa fa-arrow-left</v-icon>
        <span>{{'action.back'| t}}</span>
      </v-btn>

      <v-btn
        :to="{ name: 'login' }"
        small
        outlined
        class="mt-4 mr-4"
        :color="buttonColor"
      >
        <v-icon left>fa fa-sign-in-alt</v-icon>
        <span>{{'action.login'| t}}</span>
      </v-btn>
    </div>
  </v-alert>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'page.404',
    },
    description: {
      type: [String, null],
      default: null,
    },
  },
  computed: {
    color() {
      return 'orange lighten-1'
    },
    buttonColor() {
      if(!this.outlined) return null
      return this.color
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>